import { render, staticRenderFns } from "./EditSongOwnershipModal.vue?vue&type=template&id=61cdb6cd&scoped=true&"
import script from "./EditSongOwnershipModal.vue?vue&type=script&lang=ts&"
export * from "./EditSongOwnershipModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61cdb6cd",
  null
  
)

export default component.exports