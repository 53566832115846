


























import { Component, Vue, Prop } from 'vue-property-decorator'
import Modal from '@/components/Modal/Modal.vue'
import { RegisterSongService } from '@/services/RegisterSongService';
import RegisterSongStep1EnterSongDetails from '@/views/IntellectualProperty/RegisterSongs/RegisterSongStep1EnterSongDetails.vue';
import RegisterSongStep4Done from '@/views/IntellectualProperty/RegisterSongs/RegisterSongStep4Done.vue';
import { RequestType } from '@/configs/SongRegistrationConfig';
import { BusyService } from '@/services/BusyService';
import { ToastService } from '@/services/ToastService';
import { bus, i18n } from '@/main';

@Component({
  components:{
    Modal,
    RegisterSongStep1EnterSongDetails,
    RegisterSongStep4Done
  }
})
export default class EditSongDetailsModal extends Vue {
  @Prop() song!: any;
  store = RegisterSongService;
  init = false;
  completed = false;

  async show() {
    BusyService.showBusy();
    await RegisterSongService.loadEditSong(
      this.$route.params.ccliSongId,
      RequestType.EditSongDetails,
      this.onClose,
    );
    this.init = true;
    this.completed = false;
    BusyService.hideBusy();
    (this.$refs.modal as Modal).show();
  }

  onClose(){
    (this.$refs.modal as Modal).hide();
    this.init = false;
  }

  async onSave(isPossibleDuplicate: boolean){
    try{
      await RegisterSongService.save(undefined, isPossibleDuplicate);
      ToastService.successToast(this.$t('R.MES_SongChangesSaved').toString());
      this.completed = true;
      bus.$emit('refreshSong');
      this.completed = true;
      this.init = false;
    }catch(err){
      if (this.$refs.details){
        (this.$refs.details as RegisterSongStep1EnterSongDetails).onSubmitErrorHandling(err);
      }else{
        console.error(err);
        ToastService.dangerToast(i18n.t('R.MES_ServerError').toString());
      }
    }
    (this.$refs.modal as Modal).scrollToTop();

  }

}
