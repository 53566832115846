

























import { Component, Vue } from 'vue-property-decorator'
import Modal from '@/components/Modal/Modal.vue'
import { RegisterSongService } from '@/services/RegisterSongService';
import RegisterSongStep2EnterLyrics from '@/views/IntellectualProperty/RegisterSongs/RegisterSongStep2EnterLyrics.vue';
import RegisterSongStep4Done from '@/views/IntellectualProperty/RegisterSongs/RegisterSongStep4Done.vue';
import { RequestType } from '@/configs/SongRegistrationConfig';
import { BusyService } from '@/services/BusyService';
import { ToastService } from '@/services/ToastService';
import { bus, i18n } from '@/main';

@Component({
  components:{
    Modal,
    RegisterSongStep2EnterLyrics,
    RegisterSongStep4Done
  }
})
export default class AddSongLyricsModal extends Vue {
  store = RegisterSongService;
  init = false;
  completed = false;

  async show() {
    BusyService.showBusy();
    await RegisterSongService.loadEditSong(
      this.$route.params.ccliSongId,
      RequestType.AddLyrics,
      this.onClose,
    );
    this.init = true;
    this.completed = false;
    if ((this.$refs.comp as RegisterSongStep2EnterLyrics)){
      (this.$refs.comp as RegisterSongStep2EnterLyrics).restrictedWordsError = false;
    }
    BusyService.hideBusy();
    (this.$refs.modal as Modal).show();
  }

  onClose(){
    (this.$refs.modal as Modal).hide();
    this.init = false;
  }
  async onSave(){
    try{
      await RegisterSongService.save();
      ToastService.successToast(this.$t('R.MES_SongChangesSaved').toString());
      bus.$emit('refreshSong');
      this.completed = true;
      this.init = false;
    }catch(err){
      if (this.$refs.comp){
        (this.$refs.comp as RegisterSongStep2EnterLyrics).handleError(err);
      }else{
        console.error(err);
        ToastService.dangerToast(i18n.t('R.MES_ServerError').toString());
      }
    }
    (this.$refs.modal as Modal).scrollToTop();
  }

}
